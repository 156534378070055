<template>
  <b-nav-item-dropdown
    ref="notifications-dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template
      #button-content
    >
      <feather-icon
        :badge="totalNotificationUnread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <!-- @click="clearNotification" -->
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Thông báo
        </h4>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <div
        v-for="(notification, index) in listNotification"
        :key="index"
        class="cursor-pointer"
      >
        <!-- <p>{{ notification.leftTime }}</p> -->

        <b-media
          :style="{'background-color': !notification.viewDate ? '#f2f2f2': ''}"
          class="align-items-center"
          @click="notificationClick(notification)"
        >
          <template #aside>
            <b-avatar
              size="32"
              variant="primary"
              :src="notification.avatar"
              :text="notification.avatar"
            />
          </template>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="font-weight-bold mb-0"
            v-html="notification.content"
          />
        </b-media>
      </div>
      <infinity-loading
        v-if="listNotification.length < totalNotification"
        class="ml-1 mr-1"
        @loadMore="loadMoreNotifications"
      >
        <notification-skeleton />
      </infinity-loading>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
        variant="primary"
        block
        @click="viewAllNotifications"
      >
        Xem tất cả thông báo
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BMedia, BAvatar, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
import NotificationSkeleton from '@/views/apps/notifications/NotificationSkeleton.vue'
import InfinityLoading from '@/components/infinity-loading/InfinityLoading.vue'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    NotificationSkeleton,
    InfinityLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      queryParams: {
        pageSize: 5,
        pageNumber: 1,
      },
      isRender: true,

    }
  },
  computed: {
    ...mapState('notification', [
      'totalNotificationUnread', 'totalNotification', 'listNotification',
    ]),
  },
  created() {
    this.getListNotifications()
    if (!this.totalNotificationUnread && this.$userData() && !$.isEmptyObject(this.$userData())) {
      this.getCountNotificationUnread()
    }
  },
  methods: {
    ...mapActions('notification', ['getListNotification', 'getCountNotificationUnread', 'seenNotification']),
    async getListNotifications(loadMore) {
      if (this.$userData()) {
        const isLoadMore = !!loadMore
        await this.getListNotification({ params: this.queryParams, isLoadMore })
        this.listNotification.forEach(element => {
          element.leftTime = this.getTimeLeft(element.receiveDate)
        })
      }
    },
    loadMoreNotifications() {
      this.queryParams.pageNumber += 1
      this.getListNotifications(true)
    },

    viewAllNotifications() {
      this.$refs['notifications-dropdown'].visible = false
      if (this.$route.name !== 'view-all-notification') { this.$router.push({ name: 'view-all-notification' }) }
    },

    async notificationClick(notification) {
      if (!notification.viewDate) {
        const res = await this.seenNotification({ id: notification.id })
        if (res.status === 200) { await this.getCountNotificationUnread() }
        const notifi = this.listNotification.find(e => e.id === notification.id)
        notifi.viewDate = new Date()
      }
      if (this.$route.path !== notification.link) {
        this.$router.push(notification.link)
      }
      this.$refs['notifications-dropdown'].visible = false
    },

    getTimeLeft(commentDate) {
      moment.locale('vi')
      const time = moment(new Date(commentDate)).fromNow()
      if (time === 'vài giây tới') return 'vài giây trước'
      return time
    },
  },
}
</script>
<style lang="scss" scoped>
.media-list {
  .media {
    .media-aside {
      align-self: center !important;
    }
  }
}
.dropdown-notification {
  .dropdown-menu {
    top: 20px !important
  }
}
</style>
