<template>

  <div
    class="cursor mr-1"
    @click="$router.push({ name: 'apps-email' }).catch(() => {})"
  >
    <feather-icon
      :badge="dataCountNotify"
      badge-classes="bg-danger"
      class="text-body"
      icon="MailIcon"
      size="21"
    />
  </div>

</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('app', ['dataCountNotify']),
  },

}
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
</style>
