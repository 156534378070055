/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import setting from './setting'
import user from './user'
import business from './business'
import workers from './workers'
import statistical from './statistical'
import news from './news'
import occupationalSafetyHealth from './occupational-safety-health'
import connective from './connective'
import archive from './archiveRecord'
import message from './message'
import employment from './employment-service'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
// export default [...user, ...connective, ...business, ...workers, ...occupationalSafetyHealth, ...news, ...statistical, ...setting]
export default [...message, ...user, ...connective, ...business, ...workers, ...occupationalSafetyHealth, ...employment, ...archive, ...news, ...statistical, ...setting]
