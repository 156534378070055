<template>
  <div class="infinity-loading-component">
    <div
      v-for="(item, index) in Array(amount)"
      :key="index"
      :style="{'margin-top': `${marginTopItem}px`}"
    >
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 2,
    },
    marginTopItem: {
      type: Number,
      default: 24,
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries => {
      if (entries[0].isIntersecting && (!this.loading || !this.$isLoading())) {
        this.checkVisible()
      }
    }), { threshold: [0] })
    this.observer.observe(this.$el)
  },
  destroyed() {
    this.observer.disconnect()
  },
  methods: {
    checkVisible() {
      this.$emit('loadMore')
    },
  },
}
</script>

<style lang="scss">
</style>
