<template>
  <li
    v-if="$can(item.action, item.resource)"
    class="nav-item child-menu"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <b-col
        cols="1"
        class="icon"
      >
        <feather-icon
          :icon="item.icon || 'CircleIcon'"
        /></b-col>
      <b-col class="menu-title">
        <span class="text-truncates">{{ item.title }}</span>
      </b-col>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
// import { ref } from '@vue/composition-api'
import {
  BLink, BBadge, BCol, BRow,
} from 'bootstrap-vue'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
    BCol,
    BRow,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

    return {
      isActive,
      linkProps,
      updateIsActive,
    }
  },

}
</script>

<style lang="scss">
.child-menu {
  .menu-title {
    padding: 0 0 0 0.5rem;
    span {
      font-weight: 500;
      color: #181f28;
    }
  }
  .icon {
    padding: 0;
  }
  .text-truncates {
    overflow: hidden;
    white-space: pre-line !important;
  }
}

.active {
  .menu-title {
    span {
      color: #fff;
    }
  }
}
</style>
