<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="$serverfile + userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Thông tin cá nhân</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span> -->
    <!-- </b-dropdown-item> -->

    <!-- <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Cài đặt</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      v-if="['DepartmentOfEducation','EducationDepartment'].includes(roleType) && (dataUser.userId === dataUser.unitUserId)"
      link-class="d-flex align-items-center"
      :to="{ name: `${roleType === 'EducationDepartment'?'infomation-unit-education':'infomation-unit'}` }"
    >
      <span class="mr-50">
        <feather-icon
          size="16"
          icon="InfoIcon"
        />
      </span>
      <span>Thông tin đơn vị</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="dataUser.userId !== dataUser.unitUserId"
      @click="$router.push({name: 'info-user', params: {id: dataUser.userId, type: 'info'}})"
    >
      <span class="mr-25">
        <feather-icon
          size="16"
          icon="InfoIcon"
        />
      </span>
      <span>Thông tin tài khoản</span>
    </b-dropdown-item>
    <b-dropdown-item
      @click="showModalChangePassword"
    >
      <span class="mr-25">
        <feather-icon
          size="16"
          class="mr-50"
          icon="LockIcon"
        />
      </span>
      <span>Đổi mật khẩu</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Đăng xuất</span>
    </b-dropdown-item>
    <modal-reissue-password
      id="modalResissuePassword"
      @handleReissuePassword="handleReissuePassword"
    />
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import ModalReissuePassword from './ModalReissuePassword.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/management-user/users/constants/ConstantsApi'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ModalReissuePassword,
  },
  data() {
    return {
      userData: {},
      avatarText,
      roleType: null,
      dataUser: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    if (this.dataUser) {
      this.userData = this.dataUser
    }
    if (this.userData?.roleIdentity) {
      this.roleType = this.userData?.roleIdentity
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'home', params: { check: true } })
    },

    showModalChangePassword() {
      this.$bvModal.show('modalResissuePassword')
    },
    // Cấp lại mật khẩu cho người dùng
    async handleReissuePassword(val, validate) {
      const payload = {
        ...val,
        userId: this.dataUser.userId,
      }
      validate.validate().then(async success => {
        if (success) {
          await axiosApiInstance.post(ConstantsApi.CHANGE_PASSWORD, payload).then(res => {
            this.$hideAllPageLoading()
            if (res.status === 200) {
              this.$root.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.$bvModal.hide('modalResissuePassword')
            }
          })
        }
      }).catch(e => {
        this.$bvToast.toast(e.response.data?.errors.ConfirmNewPassword[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>
